import { useState, memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Image from "./image";
import Iframe from "./iframe";
import Icon from '@material-ui/core/Icon';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: `80%`,
    backgroundColor: theme.palette.background.paper,
    border: '6px solid #fff',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: theme.shadows[5],
  },
}));

const ModalVideo = (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={`position-relative ${props.className}`}>
        <Image caption={props.caption} src={props.sourceImage} height={props.height} srcSet={props.srcSet || props.sourceImage} className={`mw-100 rounded ${props.classImage}`} width={props.width ? props.width : '100%'} alt={props.altImage} />
        <div className="wrapper-btn-play w-100 h-100 d-flex align-items-center justify-content-center top-0">
          <button className="btn-play-video icon-play btn p-0 w-100 h-100" name="btn-play-video" onClick={handleOpen} aria-label="Dar o play no vídeo">
            <Icon className="text-danger fs-1">play_circle</Icon>
          </button>
        </div>
      </div>

      <Modal
        aria-labelledby={props.altImage}
        aria-describedby={props.altImage}
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={modalStyle}>
            <div className="ratio ratio-16x9">
              <Iframe width="560" height="315" src={props.sourceVideo} />
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default memo(ModalVideo);