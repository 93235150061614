import React, { useEffect, memo, useState } from "react";
import Service from "../services/service";
import Image from "./image";

const Index = (props) => {

  const [topPedidos, setTopPedidos] = useState([]);

  const getTopPedidos = async () => {
    var resp = await Service.get(`${Service.api}/solicitacoes`);
    if(resp){

      if(resp.message){
        if(resp.message.toUpperCase().trim() !== 'FAILED TO FETCH'){
          setTopPedidos(resp);
        }
      }else{
        setTopPedidos(resp);
      }
    }
  }

  useEffect(() => {
    getTopPedidos()
  }, []);

  return (topPedidos && topPedidos.length > 0 && <div className="row mb-3 d-flex justify-content-between align-items-center">
              <div className="col">
                <div>
                  <h3 className="lead fw-bold lh mb text-center mb-4">Últimos 10 pedidos de telefone</h3>
                  <div className="d-flex table-responsive gap-2 flex-nowrap snap">
                    {
                      topPedidos && topPedidos?.map((item, index) =>
                        <div key={item._id} className="c-pointer position-relative snap-item" onClick={() => Service.openModalDiarista(item?.colaborador)}>
                          <div className="d-flex align-items-center gap-2 position-absolute top-0 start-0 m-2 bg-body-secondary p-1 pe-4 rounded" style={{'--bs-bg-opacity': '.5', 'right': 0}}>
                            <Image src={Service.parseURLImage(item?.contratante)} width="32" height="32" alt={item?.contratante?.nome} className="rounded image-perfil" />
                            <div>
                              <div className="text-muted d-block lh"><small className="d-block lh">{Service.formatDate(item?.createdAt, 'HH:mm:ss')} hrs</small></div>
                              <div className="lh"><small className="d-block lh text-truncate">{item?.contratante?.nome?.toLowerCase()} viu...</small></div>  
                            </div>
                          </div>
                          <Image src={Service.parseURLImage(item?.colaborador)} width="160" height="160" alt={item?.colaborador?.nome} className="rounded image-perfil" />
                          <p className="m-0 fw-bold position-absolute bottom-0 bg-white rounded-pill badge bg-secondary text-dark px-2 m-2">{item?.colaborador?.nome}/{item?.colaborador?.uf}</p>
                        </div>
                      )}
                  </div>
                </div>
              </div>                
            </div>)
}

export default memo(Index);